export default [
  {
    title: 'Giriş',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Online Ödeme',
    icon: 'CreditCardIcon',
    route: 'PaymentSearch',
    resource: 'PAYMENT',
    action: 'read',
  },
  {
    title: 'Ödemeler',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Ödeme Geçmişi',
        icon: 'ArchiveIcon',
        route: 'PaymentHistory',
        resource: 'PAYMENT_HISTORY_LIST',
        action: 'read',
      },
      {
        title: 'Ödeme Takvimi',
        icon: 'CalendarIcon',
        route: 'PaymentCollections',
        resource: 'PAYMENT_COLLECTION_LIST',
        action: 'read',
      },
      {
        title: 'Ödeme İtirazları',
        icon: 'ListIcon',
        route: 'DisapprovalPayments',
        resource: 'DISAPPROVAL_PAYMENTS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Cari İşlemler',
    icon: 'BookIcon',
    children: [
      {
        title: 'İşlemler',
        icon: 'BookIcon',
        route: 'CurrentAccounts',
        resource: 'CURRENT_ACCOUNTS_LIST',
        action: 'read',
      },
      {
        title: 'Yeni Cari Hesap',
        icon: 'PlusCircleIcon',
        route: 'CurrentAccountSearch',
        resource: 'CURRENT_ACCOUNTS_ADD',
        action: 'read',
      },
    ],
  },
  // {
  //   title: 'Bize Ulaşın',
  //   icon: 'MapPinIcon',
  //   route: 'CompanyView',
  //   resource: 'COMPANY_VIEW',
  //   action: 'read',
  // },
  {
    title: 'Müşteriler',
    icon: 'UsersIcon',
    route: 'Customers',
    resource: 'CUSTOMER_CARD_LIST',
    action: 'read',
  },
  {
    title: 'Kullanıcılar',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcı Listesi',
        route: 'Users',
        resource: 'USERS_LIST',
        action: 'read',
      },
      {
        title: 'Kullanıcı Seviyeleri',
        route: 'UserTypes',
        resource: 'USER_TYPES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Banka Pos Ayarları',
        route: 'PosConfigs',
        resource: 'POS_CONFIGS_LIST',
        action: 'read',
      },
      {
        title: 'SMTP Ayarları',
        route: 'Smtpconfig',
        resource: 'SMTP_CONFIG',
        action: 'read',
      },
      {
        title: 'Firma Bilgileri',
        route: 'Company',
        icon: 'BookIcon',
        resource: 'COMPANY_EDIT',
        action: 'read',
      },
      {
        title: 'Duyurular',
        route: 'Announcements',
        icon: 'BellIcon',
        resource: 'ADMIN_ANNOUNCEMENTS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Raporlar',
    icon: 'ChartIcon',
    children: [
      {
        title: 'Güncel Durum',
        route: 'CurrentStatus',
        resource: 'REPORTS_CURRENT_STATUS',
        action: 'read',
      },
      {
        title: 'Bankalar',
        route: 'BanksReport',
        resource: 'REPORTS_BANKS',
        action: 'read',
      },
      {
        title: 'Müşteriler',
        route: 'CustomersReport',
        resource: 'REPORTS_CUSTOMERS',
        action: 'read',
      },
      {
        title: 'Temsilciler',
        route: 'UsersReport',
        resource: 'REPORTS_USERS',
        action: 'read',
      },
    ],
  },
]
