<template>
  <b-row class="match-height">
    <b-col
      class="d-flex justify-content-center"
    >
      <div>
        © {{ new Date().getFullYear() }}
        <b-link
          href="https://code64.com.tr/"
          target="_blank"
        >vPos64 - Online Tahsilat </b-link>
        <span class="d-none d-sm-inline-block"> Tüm hakları saklıdır.</span>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-b-modal.modal-privacy
          variant="flat-primary"
          pill
        >
          Gizlilik Politikası
        </b-button>
        <b-button
          variant="flat-danger"
          pill
          to="/about"
        >
          Bize Ulaşın
        </b-button>
      </div>
    </b-col>
    <b-col
      cols="auto"
    >
      <b-img
        src="/images/logos.png"
        width="250"
        class="ml-auto"
      />
    </b-col>
    <privacy-modal />
  </b-row>
</template>

<script>
import {
  BLink, BImg, BRow, BCol, BButton,
} from 'bootstrap-vue'
import PrivacyModal from '@/layouts/components/PrivacyModal.vue'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    PrivacyModal,
  },
}
</script>
